import { Title } from '@solidjs/meta';
import {
	Button,
	Container,
	Dialog,
	DialogContent,
	DialogTrigger,
	Heading,
	HorizontalRule,
	Link,
	Page,
	Section,
	TextLink,
} from '@troon/ui';
import { createEffect, createSignal, Show } from 'solid-js';
import { Icon } from '@troon/icons';
import { useNavigate } from '@solidjs/router';
import { FrequentlyAskedQuestions } from '../../../components/faqs';
import { Grid, GridHalf } from '../../../components/layouts/grid';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { useUser } from '../../../providers/user';
import { AuthFlow } from '../../../partials/auth/auth';
import { UpsellAccessSection } from '../../../components/upsells/access';
import type { JSX } from 'solid-js';

export default function OldTroonCardLanding() {
	const user = useUser();
	const navigate = useNavigate();
	const [shouldRedirect] = createSignal(!user());

	createEffect(() => {
		if (shouldRedirect() && user()?.me.card?.id) {
			navigate(`/card/${user()?.me.card?.id}`);
		}
	});
	return (
		<>
			<Title>Troon Card | Troon</Title>

			<Hero src={`${getConfigValue('IMAGE_HOST')}/digital/hero/troon-card.jpeg`}>
				<Heading as="h1">2024 Troon Card</Heading>
				<Show when={user()?.me.card}>
					{(card) => (
						<Button as={Link} href={`/card/${card().id}`} class="size-fit shrink grow-0">
							Book with Troon Card
						</Button>
					)}
				</Show>
			</Hero>

			<Container>
				<Page>
					<Show when={!user()}>
						<div class="z-30 -mt-32 flex flex-row flex-wrap items-center justify-between gap-4 rounded bg-white p-4 shadow-lg md:-mt-36 xl:flex-nowrap">
							<div class="flex flex-row items-center gap-4">
								<Icon name="user-circle" class="shrink-0 self-start text-3xl text-brand lg:self-center" />
								<p class="shrink grow">
									Log in to your Troon Rewards account to book tee times using your Troon Card benefits.
								</p>
							</div>
							<Dialog key="auth">
								<DialogTrigger as={Link} href="/auth" class="grow lg:shrink lg:grow-0">
									Log in
								</DialogTrigger>
								<DialogContent autoWidth noPadding noClose floatingClose>
									<AuthFlow />
								</DialogContent>
							</Dialog>
						</div>
					</Show>

					<UpsellAccessSection>
						<Heading as="h2">Better benefits, more access.</Heading>
						<p>
							The Troon Card has evolved into Troon Access! Enjoy exclusive benefits at over 150+ Troon courses
							including Kapalua, Troon North, and Gamble Sands.
						</p>

						<div class="flex justify-start gap-4">
							<Button class="w-fit shrink grow-0" as={Link} href="/access">
								Learn more<span class="sr-only"> about Troon Access</span>
							</Button>
							<Button
								class="w-fit shrink grow-0"
								appearance="transparent-current"
								as={Link}
								href="/access/troon-card-vs-troon-access"
							>
								<Icon name="info" /> Troon Card vs. Troon Access
							</Button>
						</div>
					</UpsellAccessSection>

					<HorizontalRule />

					<Section id="two-for-one">
						<Heading as="h2" class="mx-auto max-w-lg text-center">
							Troon Card “2 for 1’s”
						</Heading>
						<p class="mx-auto max-w-lg text-center">
							The Troon Card 2-for-1 is a benefit allowing a Troon Card holder and one guest to play a round of golf for
							the price of one Troon Card rate at specific courses.
						</p>
						<Container size="small">
							<div class="overflow-hidden rounded border border-neutral">
								<FrequentlyAskedQuestions content={terms} />
							</div>
						</Container>

						<Grid>
							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in Arizona
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – June 2, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
										<li>*Blackout Dates for 2 for 1 use: Thursday, November 23, 2023 – Sunday, November 26, 2023 </li>
										<li>*Blackout Dates for 2 for 1 use: Monday, February 5, 2024 – Sunday, February 11, 2024</li>
									</ul>

									<p class="font-semibold">June 3, 2024 – August 29, 2024</p>
									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 9am</li>
									</ul>

									<p class="font-semibold">August 30, 2024 – December 31, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
										<li>*Blackout Dates for 2 for 1 use: Thursday, November 28, 2024 – Sunday, December 1, 2024</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in California
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – June 2, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>

									<p class="font-semibold">June 3, 2024 – August 29, 2024</p>

									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>

									<p class="font-semibold">August 30, 2024 – December 31, 2024</p>

									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses Outside Arizona, California, and Hawaii
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – December 31, 2024</p>
									<ul>
										<li>2 days in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>

							<GridHalf class="overflow-hidden rounded-md border border-neutral">
								<Heading as="h3" size="p" class="bg-brand-700 px-4 py-2 font-semibold text-white">
									All Participating Courses in Hawaii
								</Heading>
								<div class="flex flex-col gap-4 p-4">
									<p class="font-semibold">September 1, 2023 – December 31, 2024</p>
									<ul>
										<li>1 day in advance</li>
										<li>Monday – Sunday: After 11am</li>
									</ul>
								</div>
							</GridHalf>
						</Grid>
					</Section>

					<HorizontalRule />

					<Grid>
						<GridHalf>
							<Section>
								<Heading as="h2" size="h2">
									Frequently asked questions
								</Heading>

								<p>You have questions, we have answers. Not seeing what you’re looking for?</p>

								<Button as={Link} href="/about/contact" appearance="secondary" class="size-fit">
									Contact support
								</Button>
							</Section>
						</GridHalf>

						<GridHalf border>
							<FrequentlyAskedQuestions content={faqs} />
						</GridHalf>
					</Grid>
				</Page>
			</Container>
		</>
	);
}

const terms: Record<string, () => JSX.Element> = {
	'Usage and Limitations': () => (
		<ul class="flex list-disc flex-col gap-4 ps-6">
			<li>
				State and Regional Troon Cards offer five redemptions each, while the National Troon Card offers ten
				redemptions.
			</li>
			<li>Cardholders can use the 2-for-1 offer only once per Troon Card facility included with the purchased card.</li>
			<li>
				2-for-1 tee-times can be booked as early as 12:01AM, as many days in advance as is referenced in the calendar
				below
			</li>
		</ul>
	),
	Conditions: () => (
		<ul class="flex list-disc flex-col gap-4 ps-6">
			<li>
				The 2-for-1 offer is based on the standard Troon Card rate and can only be played within the restrictions stated
				in the calendar below.
			</li>
			<li>
				It cannot be combined with other offers or promotions, and Troon Rewards status level discounts do not apply.
			</li>
		</ul>
	),
	'Additional Points': () => (
		<ul class="flex list-disc flex-col gap-4 ps-6">
			<li>
				When being awarded Troon Rewards points for using the 2-for-1 offer, only the Troon Cardholder receives the
				point value of the standard Troon Card rate.
			</li>
			<li>The offer is non-transferable.</li>
			<li>It does not apply to participating bonus states or international locations.</li>
			<li>It is not applicable to facilities added to the Troon Card program after the start of the current year.</li>
			{/* <li>Please check the Troon Card Course Fees page for individual club restrictions</li> */}
		</ul>
	),
	'How to book': () => (
		<ul class="flex list-disc flex-col gap-4 ps-6">
			<li>
				To book a 2 for 1 tee time, simply book it as you would any other Troon Card tee time (by phone or online). As
				long as you are within the stated restrictions, your rate will be adjusted at check in.
			</li>
			<li> Please check with the individual clubs regarding holiday tee times.</li>
		</ul>
	),
};

const faqs: Record<string, () => JSX.Element> = {
	'What benefits do Troon Cards provide?': () => (
		<>
			<p>
				Troon Cards provide Cardholders and guest(s) with rates that are up to 50% off at participating Troon
				facilities. TwoSome Cardholders can bring up to one guest and FourSome Cardholders may bring up to three guests.
				Guest(s) are not required.
			</p>
			<ul>
				<li>
					NEW in 2024 – Extended Booking Window: Troon Card tee times can now be reserved 78 hours in advance (example:
					6pm on Monday for a Friday morning tee time)
				</li>
				<li>
					Best Rate Guarantee: Troon Card’s Best Rate Guarantee allows Cardholders to book any standard{' '}
					<b>ONLINE RATE</b> and receive 15% off their golf fee at check in. This benefit is in effect outside the 3 day
					advance booking window! Not valid on prepaid rounds. Cardholder and guests must qualify for online rate. Rate
					must be for same day and time. Excludes actual Troon Card rate.
				</li>
				<li>
					“2 for 1” golf offers: Included with the purchase of any 2024 Troon Card, is a one-time “2 for 1” at the Troon
					Card rate (five redemptions each for State and Regional Troon Cards and ten redemptions for the National Troon
					Card).
				</li>
				<li>
					The advance booking of the one-time “2 for 1” varies based on time of year (
					<TextLink href="/card/#two-for-one">Click for Flexible “2 for 1” Calendar</TextLink>
					).
				</li>
				<li>
					Birthday Round: Cardholders receive 25% off Troon Card rate on the day of their Birthday. Must provide valid
					drivers license at check in to redeem. 25% off does not apply to Cardholder guest (s).
				</li>
				<li>
					Replay Rates: Cardholders and qualifying guests may enjoy replay rounds from as little as $25 per player.
					Rounds may be booked at the conclusion of the first round upon course availability
				</li>
				<li>25% off Golf Club Rental Fees for Cardholder at Participating Courses</li>
				<li>10% off Merchandise at Participating Golf Shops and Participating Golf Course Restaurants</li>
				<li>Complimentary Skills Clinics</li>
				<li>Customized Experience Packages</li>
				<li>Troon Card E-News: Monthly offers exclusively for Troon Cardholders</li>
				<li>
					<TextLink
						href="https://www.facebook.com/groups/275222245991414/402166486630322/?ref=notif%25ACif_t%3Dgroup_activity"
						target="_blank"
						rel="noopener"
					>
						Troon Card Facebook Group
					</TextLink>
				</li>
				<li>
					<TextLink href="/tee-times">Book Tee Times</TextLink> anytime online or by phone.
				</li>
			</ul>
		</>
	),
	'How far in advance can tee times be reserved?': () => (
		<p>
			<TextLink href="/tee-times">Reserve tee times</TextLink> 3+ days in advance (example: 6pm on Monday for a Friday
			morning tee time) to receive Troon Card rates up to 50% off standard rates OR book any standard <b>ONLINE RATE</b>{' '}
			outside of the three day window and receive 15% off your golf fee at check in. Visit{' '}
			<TextLink href="/card/terms">terms and conditions</TextLink> for details. “2 for 1” Golf Offers may be booked
			based on the <TextLink href="/card/#two-for-one">Flexible “2 for 1” Calendar</TextLink>. Some restrictions apply.
		</p>
	),
	// 'How and when do I use my "2 for 1" golf offers?': () => (
	// 	<p>
	// 		Log into your account at <TextLink href="https://troon.com/account/login">TroonRewards.com</TextLink>. On the top
	// 		right of the page there is a link named “Troon Card Offers”. That link will take you to a chart that will show
	// 		each club you are entitled to your one time “2 for 1”. There is no dedicated “2 for 1” booking portal. You are not
	// 		required to present a certificate when you check in for your tee time. Simply book your tee time based upon the
	// 		restrictions outlined in the following calendar{' '}
	// 		<TextLink href="/card/#two-for-one">Click for Calendar</TextLink>
	// 	</p>
	// ),
	'How many greens fees does my "2 for 1" cover?': () => (
		<p>
			TwoSome and FourSome cards cover two greens fees for the price of one. If you have a group of two you pay one
			greens fee. If you have a group of three you pay two greens fees. If you have a group of four you pay three greens
			fees.
		</p>
	),
	'Are there restrictions on the "2 for 1” golf offers?': () => (
		<p>
			(<TextLink href="/card/#two-for-one">See Flexible “2 for 1” Calendar</TextLink>
			). Valid beginning November 1, 2023 through December 31, 2024.
		</p>
	),
	'When can I start using my Troon Card?': () => (
		<p>
			It will take up to 4 business days to process your Troon Card order. You will receive a welcome email with your
			Troon Card Number and a general overview.
		</p>
	),
	'How long is my Troon Card valid for?': () => (
		<p>All 2024 Troon Cards are valid for use starting November 1, 2023, through December 31, 2024.</p>
	),
	'Can a card number have more than one name or a company name on it?': () => (
		<p>Card numbers are for individuals only.</p>
	),
	'Does the Cardholder need to be present at all times?': () => (
		<p>The Cardholder must be playing when the card number is being used. A picture ID is required at check-in.</p>
	),
	'Can I let another person use my card number?': () => <p>Card numbers are non-transferable.</p>,
	'What is the cancellation policy on Troon Card tee times?': () => (
		<p>
			The cancellation policy is 24 hours in advance. Cancellations within this period may be subject to cancellation
			fees at the golf course’s discretion.
		</p>
	),
	'Will I receive a physical card?': () => <p>The program is all-electronic, nothing physical is sent to you.</p>,
};

export const route = { info: { hasHero: true } };
